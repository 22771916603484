<template>
  <div class="contrast" @click="clear">
    <div class="contrast_banner">
      <div class="header">器械信息</div>
      <div class="uploadBox" v-for="(item, index) in list" :key="index">
        <div class="choseBox">
          <img
            v-if="item.headerList"
            :src="item.headerList.apparatusFileId"
            alt=""
          />

          <i
            v-if="item.headerList"
            class="el-icon-error"
            @click="close(item)"
          ></i>
        </div>
        <div class="title">
          <span v-if="item.headerList" class="noImg">{{
            item.headerList.apparatusName
          }}</span>
          <span v-if="!item.headerList" class="noImg2">
            暂无器械
          </span>
        </div>
        <div class="btn">
          <el-button plain @click.stop="cascaderOn(item)"
            >{{ item.headerList ? "更换器械" : "选择器械" }}
          </el-button>
          <div class="myCascader" v-show="item.isShow">
            <div class="myCascader__header">选择品牌</div>
            <div class="myCascader__bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                  v-for="(breaditem, index) in item.breadlist"
                  :key="index"
                  @click.stop.native="breadsplit(item, index)"
                  >{{ breaditem }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
            <div class="myCascader__list">
              <ul class="myCascader__list_one">
                <li
                  class="myCascader__list_one-li"
                  v-for="(i, cindex) in listAZ"
                  :key="cindex"
                  v-show="item.isShow1"
                  @click.stop="chose()"
                >
                  <span>{{ i.firmLetter }}</span>
                </li>

                <div class="secend" v-show="item.isShow1">
                  <ul class="myCascader__list_two">
                    <div v-for="(i, iindex) in item.result" :key="iindex">
                      <div class="myCascader__list_two-header">
                        {{ i.firmLetter }}
                      </div>
                      <li
                        class="myCascader__list_two-li"
                        v-for="(c, cindex) in i.data"
                        :key="cindex"
                        @click.stop="getFCate(c.firmId, index, item)"
                      >
                        <div><img :src="c.apparatusFileId" /></div>
                        <div>
                          <span>{{ c.firmName }}</span>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>

                <div class="last" v-if="item.isShow2">
                  <ul class="myCascader__list_three">
                    <div v-for="(d, dindex) in item.threeList" :key="dindex">
                      <div class="myCascader__list_three-header">
                        {{ d.catName }}
                      </div>
                      <li
                        class="myCascader__list_three-li"
                        v-for="(di, diindex) in d.children"
                        :key="diindex"
                        @click.stop="getList(di, index, item)"
                      >
                        <span>
                          {{ di.catName }}
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
                <div class="last2" v-if="item.isShow3">
                  <ul>
                    <li
                      v-for="(dis, disindex) in item.threeList2"
                      :key="disindex"
                      @click.stop="getIds(dis, index, item)"
                      :class="{
                        disable: constrastIds.indexOf(dis.apparatusId) != -1,
                      }"
                    >
                      {{ dis.apparatusName }}
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parms" v-for="(item, index) in parmList" :key="index">
      <el-collapse v-model="openList" v-if="openList.length != 0">
        <el-collapse-item
          :title="item.info.paramKey"
          :name="index"
          class="parms_title-header"
        >
          <div class="parms_table">
            <div
              class="parms_table-content"
              v-for="(citem, index) in item.child"
              :key="index"
            >
              <div class="table-content_title">{{ citem.paramKey }}</div>
              <div v-if="$route.params.type == 1" class="table-content_text">
                <div v-for="(text, tindex) in citem.paramValue" :key="tindex">
                  {{ text }}
                </div>
              </div>
              <div v-if="$route.params.type == 2" class="table-content_text">
                <div v-for="(text, tindex) in citem.paramMapping" :key="tindex">
                  {{ text }}
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import api from "../api/api";
import lib from "../api/lib";
import cnchar from "cnchar";
export default {
  data() {
    return {
      activeNames: [],
      listAZ: [
        
      ],
      list: [],
      manufacturer: [],
      cascader1: "",
      cascader2: "",
      cascader3: "",
      threeList: [],
      threeList2: [],
      constrastIds: [],
      parmList: [],
    };
  },
  created() {
    this.init();
  },
  watch: {
    constrastIds(val) {
      this.getDiff(val);
    },
  },
  computed: {
    openList() {
      let arr = [];
      for (let i = 0; i <= this.parmList.length; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  methods: {
    init() {
      this.searchApparatus().then((resolve) => {
        
        for (let i = 0; i < 3; i++) {
          let data = {};
          data.isShow = false;
          data.result = resolve;
          data.threeList = [];
          data.threeList2 = [];
          (data.breadlist = ["厂商"]), (data.isShow1 = true);
          data.isShow2 = false;
          data.isShow3 = false;
          this.list.push(data);
        }
      });
    },
    cascaderOn(item) {
      item.isShow = true;
      if (!item.threeList) {
        item.isShow1 = true;
      }
    },
    breadsplit(item, index) {
      item.breadlist.splice(index + 1, item.breadlist.length - index + 1);

      let name = "isShow" + (index + 1);
      item[name] = true;
    },
    searchApparatus() {
      let data = {
        keyword: "",
      };
      return new Promise((resolve, reject) => {
        
        lib.getFirm().then((res) => {
          let data = res.data.data;
          let dest = [];
          // data.forEach((item) => {
            
          //   let str = item.apparatusName
          //     .split("")[0]
          //     .spell("first")
          //     .toUpperCase();
          //   item.titleText = str;
          // });
          // this.listAZ.forEach((citem) => {
          //   let arr = data.filter((item) => {
          //     return item.titleText == citem.title;
          //   });
          //   if (arr.length != 0) {
          //     dest.push(arr);
          //   }
          // });
          data.forEach((item) => {
            this.listAZ.push(item);
          });
          
          resolve(data);
        });
      });
    },
    clear() {
      this.list.forEach((item) => {
        item.isShow = false;
      });
    },
    getFCate(id, index, item) {
      let data = {
        firmId: id,
      };

      item.isShow1 = false;
      item.isShow2 = true;
      item.breadlist[1] = "系列";
      lib.getFCate(data).then((res) => {
        item.threeList = res.data.data;
        // this.list[index].threeList = this.threeList;
      });
    },

    chose() {},
    getList(item, index, allitem) {
      allitem.isShow2 = false;
      allitem.isShow3 = true;
      allitem.breadlist[2] = "名称";
      this.threeList2 = item.children;
      this.list[index].threeList2 = this.threeList2;
    },
    getIds(item, idx, allitem) {
      if (this.constrastIds.indexOf(item.apparatusId) != -1) {
        return;
      }
      let data = {
        id: item.apparatusId,
        index: idx,
      };
      allitem.isShow = false;
      this.constrastIds.splice(idx, 1, data);
      // this.diffParam(constrastIds,idx)
    },
    close(item) {
      item.headerList = null;
      item.isShow = true;
      item.isShow1 = false;
      item.isShow2 = false;
      item.isShow3 = false;
      item.breadlist = ["品牌"];
      item.threeList = null;
      item.threeList2 = null;
      console.log(item);
    },
    getDiff(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      let a = {
        ids: ids,
      };
      if (this.$route.params.type == 1) {
        this.diffParam(a, val);
      } else {
        this.diffComment(a, val);
      }
    },
    diffParam(data, val) {
      lib.diffParam(data).then((res) => {
        let data = res.data.data.apparatus;
        data.forEach((item) => {
          val.forEach((citem) => {
            if (item.apparatusId == citem.id) {
              this.list[citem.index].headerList = item;
              this.list.splice(this.list.length);
            }
          });
        });

        this.parmList = res.data.data.params;
      });
    },
    diffComment(data, val) {
      lib.diffComment(data).then((res) => {
        let data = res.data.data.apparatus;
        data.forEach((item) => {
          val.forEach((citem) => {
            if (item.apparatusId == citem.id) {
              this.list[citem.index].headerList = item;
              this.list.splice(this.list.length);
            }
          });
        });

        this.parmList = res.data.data.params;
      });
    },
  },
};
</script>
<style lang="scss" >
.myCascader__bread {
  .el-breadcrumb__inner {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
.contrast {
  .parms {
    
    .el-collapse {
      border-top: none;
      .el-collapse-item {
        
        &.parms_title-header {
          .el-collapse-item__header {
            font-size: 21px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            background: #fbfbfb;
          }
        }
      }
      .el-collapse-item__wrap{
        border-bottom: none;
        border-top: 1px solid #D2D4D6;
        background: transparent;
      }
      .el-collapse-item__content{
        padding-bottom: 0;
      }
    }
  }
  .btn {
    .el-button {
      border-color: #0394ff;
      background: #fff;
      color: #0394ff;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.none {
  display: none;
}
.contrast {
  // min-height: 990px;
  .contrast_banner {
    display: flex;
    .header {
      background: #ffffff;
      width: 25%;
      height: 320px;
      border: 1px solid #e9e9e9;
      text-align: center;
      line-height: 320px;
      font-size: 29px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .uploadBox {
      background: #ffffff;
      width: 25%;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;
      border: 1px solid #e9e9e9;
      .choseBox {
        width: 200px;
        height: 141px;
        border: 1px dashed #c4c4c4;
        background: url("../assets/choseContrast.png") no-repeat;
        background-size: 100% auto;
        background-position: center;
        margin: 0 auto;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 7px;
        }
        i {
          font-size: 20px;
          color: #dedede;
          position: absolute;
          top: -20px;
          right: -20px;
        }
      }
      .title {
        font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
        .noImg {
          
          color: #333333;
        }
        .noImg2{
          
          color: #999999;
        }
      }
      .btn {
        position: relative;
      }
    }
  }
  .parms {
    margin-top: 39px;
    .parms_title {
      display: flex;
      align-items: center;
      line-height: 56px;
      height: 56px;
      border-bottom: 1px solid #d2d4d6;
      .line {
        display: inline-block;
        width: 21px;
        height: 29px;
        border: 1px dashed #999999;
        position: relative;
        .mins {
          width: 16px;
          height: 4px;
          background: #999999;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .parms_title-header {
        font-size: 21px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .parms_title-text {
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .parms_table {
      height: auto;
      margin-top: 30px;

      .parms_table-content {
        display: flex;
        // align-items: center;
        .table-content_title {
          width: 25%;
          // height: 65px;
          line-height: 65px;
          text-align: center;
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #0394ff;
          border: 1px solid #e9e9e9;
          background: #fff;
        }
        .table-content_text {
          height: 65px;
          flex-grow: 1;
          line-height: 65px;
          text-align: center;
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          border: 1px solid #e9e9e9;
          margin-left: -1px;
          background: #fff;
        }
        
      }
    }
  }
  .myCascader {
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;

    cursor: pointer;
    .myCascader__header {
      padding: 12px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .myCascader__bread {
      background: #fff;
      padding: 12px;
      cursor: pointer;
    }
    .myCascader__list {
      cursor: pointer;
      display: inline-block;
      width: 300px;
      position: relative;
      li {
        list-style: none;
      }
      .myCascader__list_one {
        // position: relative;
        width: 30px;
        .myCascader__list_one-li {
          font-size: 14px;
          font-family: Helvetica-Oblique;
          font-weight: normal;
          color: #333333;
          padding: 2px 0;
          background: #fff;
          text-align: center;
          cursor: pointer;
          position: relative;
          z-index: 10;
          span {
            padding: 4px;
          }
          &:hover {
            span {
              background: #0394ff;
              color: #fff;
            }
          }
        }
        .secend {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: calc(100% - 29px);
          background: #fff;
          min-width: 300px;
          z-index: 2;
          .myCascader__list_two {
            .myCascader__list_two-header {
              font-size: 14px;
              font-family: Helvetica-Oblique;
              font-weight: normal;
              color: #0092ff;
              padding-left: 22px;
              text-align: left;
              background: #f8f9fc;
              margin-left: 29px;
            }
            .myCascader__list_two-li {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              padding: 7px 30px;
              background: #fff;
              display: flex;
              // justify-content: space-between;
              cursor: pointer;
              margin-left: 29px;
              img {
                width: 22px;
                // margin-right: 40px;
              }
              &:hover {
                background: #0394ff;
                color: #fff;
              }
            }
          }
        }
        .last {
          position: absolute;
          left: 0px;
          width: 100%;
          top: 0;

          background: #fff;
          border-radius: 7px;
          .myCascader__list_three {
            .myCascader__list_three-header {
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              height: 37px;
              line-height: 37px;
              padding: 12px 30px;

              background: #f8f9fc;
            }
            .myCascader__list_three-li {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              height: 37px;
              line-height: 37px;
              padding: 12px 30px;
              &:hover {
                background: #0394ff;
                color: #fff;
              }
            }
          }
        }
        .last2 {
          position: absolute;
          left: 0px;
          width: 100%;
          top: 0;

          background: #fff;
          border-radius: 7px;
          li {
            font-size: 12px;
            height: 37px;
            line-height: 37px;
            color: #666666;
            padding: 12px 30px;
            &:hover {
              background: #0394ff;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>